import * as React from 'react';

type HtmlBreaksProps = {
  includeBreaks?: boolean;
};

/**
 * Convert a string with new line character (\n)
 * to HTML with breaks (<br />)
 *
 * @param html string
 * @param opts object
 */
export const HtmlBreaks = (
  html: string,
  { includeBreaks }: HtmlBreaksProps = { includeBreaks: true },
) =>
  html.split('\n').map((s, i) => (
    <React.Fragment key={i}>
      {s}
      {includeBreaks && <br />}
    </React.Fragment>
  ));

/**
 * Renders a string containing HTML, and sanitizes it
 *
 * @param str string
 */
export const HtmlSanitizer = ({
  html,
  className,
  tagName = 'div',
}: {
  html: string;
  className?: string;
  tagName?: 'div' | 'span';
}) => {
  if (tagName === 'span') {
    return <span className={className} dangerouslySetInnerHTML={{ __html: html }} />;
  }
  return <div className={className} dangerouslySetInnerHTML={{ __html: html }} />;
};
