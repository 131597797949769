import * as React from 'react';
import { Helmet } from 'react-helmet';

export type MetaProps = {
  title: string;
  metaDesc: string;
  metaKeywords: string;
  metaRobotsNoindex: string;
  metaRobotsNofollow: string;
  canonical?: string;
  breadcrumbs: {
    text: string;
    url: string;
  }[];
};

export const Meta = ({ metafields }: { metafields?: MetaProps }) => {
  const title = metafields?.title ?? 'Pavilion Cosmetic Medicine';
  return (
    <Helmet>
      <title>{title ?? 'Pav'}</title>
      <meta charSet="utf-8" />
      <meta name="description" content={metafields?.metaDesc && metafields.metaDesc} />
      {metafields?.metaRobotsNoindex && metafields?.metaRobotsNofollow && (
        <meta
          name="robots"
          content={`${metafields.metaRobotsNoindex},${metafields.metaRobotsNofollow}`}
        />
      )}
      {metafields?.canonical != null && <link rel="canonical" href={metafields.canonical} />}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
      <link
        href="https://fonts.googleapis.com/css2?family=Sen:wght@400;700;800&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};
