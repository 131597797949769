import React, { useReducer } from 'react';

type State = {
  headerHover: {
    isActive: boolean;
    activeIndex: number | undefined;
  };
  headerMobile: {
    isActive: boolean;
    isAnimating: boolean;
  };
  refNodes: {
    footerEnquire?: HTMLDivElement;
  };
};

type Actions =
  | {
      type: 'HEADER_HOVER_SHOW';
      index: number;
    }
  | {
      type: 'HEADER_HOVER_HIDE';
    }
  | {
      type: 'HEADER_MOBILE_TOGGLE';
    }
  | {
      type: 'HEADER_MOBILE_HIDE';
    }
  | {
      type: 'HEADER_MOBILE_ANIMATE_END';
    }
  | {
      type: 'REF_NODE_FOOTER_ENQUIRE_SET';
      node: HTMLDivElement;
    };

const initialState: State = {
  headerHover: {
    isActive: false,
    activeIndex: undefined,
  },
  headerMobile: {
    isActive: false,
    isAnimating: false,
  },
  refNodes: {
    footerEnquire: undefined,
  },
};
const store = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Actions>;
}>({
  state: initialState,
  dispatch: () => null,
});
const { Provider: StateProvider } = store;

function reducer(state: State, action: Actions) {
  switch (action.type) {
    case 'HEADER_HOVER_SHOW':
      return {
        ...state,
        headerHover: {
          ...state.headerHover,
          activeIndex: action.index,
          isActive: true,
        },
      };
    case 'HEADER_HOVER_HIDE':
      return {
        ...state,
        headerHover: {
          ...state.headerHover,
          isActive: false,
        },
      };
    case 'HEADER_MOBILE_TOGGLE':
      return {
        ...state,
        headerMobile: {
          isAnimating: true,
          isActive: !state.headerMobile.isActive,
        },
      };
    case 'HEADER_MOBILE_HIDE':
      return {
        ...state,
        headerMobile: {
          isAnimating: true,
          isActive: false,
        },
      };
    case 'HEADER_MOBILE_ANIMATE_END':
      return {
        ...state,
        headerMobile: {
          ...state.headerMobile,
          isAnimating: false,
        },
      };
    case 'REF_NODE_FOOTER_ENQUIRE_SET':
      return {
        ...state,
        refNodes: {
          ...state.refNodes,
          footerEnquire: action.node,
        },
      };
    default:
      throw new Error('No case found');
  }
}

const Provider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <StateProvider value={{ dispatch, state }}>{children}</StateProvider>;
};

export { store, Provider };
