import * as React from 'react';
import LogoSVG from './logo.inline.svg';
import LogoWhiteSVG from './logo-white.inline.svg';
import styles from './logo.module.css';

const createLogo = (logo: JSX.Element) => <div className={styles.logo}>{logo}</div>;

export const Logo = () => createLogo(<LogoSVG />);

export const LogoWhite = () => createLogo(<LogoWhiteSVG />);
