import * as React from 'react';
import { PageTemplateProps } from 'shared/types/pages';
import { Layout } from 'ui/components/organisms/layout/layout';

type MemoLayoutProps = PageTemplateProps<unknown> & {
  children: React.ReactNode;
};

const MemoLayout = ({ data, children }: MemoLayoutProps) => {
  return <Layout pageData={data}>{children}</Layout>;
};

export default MemoLayout;
