export function exists<T>(t: T | undefined | null): t is T {
  return t != null;
}

export function checkExists<T>(x: T | null | undefined): T {
  if (x == null) {
    throw new Error('null or undefined argument');
  }
  return x;
}
