import * as React from 'react';
import classNames from 'classnames';
import { ButtonVariants } from 'ui/components/atoms/button/button';
import { TextSub1 } from 'ui/components/atoms/typography/typography';
import buttonStyles from 'ui/components/atoms/button/button.module.css';
import styles from './link.module.css';

type LinkTargets = '_self' | '_blank';

type LinkProps = {
  children: any;
  href: string;
  target?: LinkTargets;
  className?: string;
};

const BaseLink = ({
  href,
  children,
  variant,
  target,
  className,
}: LinkProps & { variant: ButtonVariants }) => (
  <a
    href={href}
    target={target}
    className={classNames(
      buttonStyles.button,
      styles.link,
      {
        [buttonStyles.primary]: variant === 'primary',
        [buttonStyles.primaryInverted]: variant === 'primaryInverted',
        [buttonStyles.secondary]: variant === 'secondary',
      },
      className,
    )}>
    <TextSub1 color="inherit">{children}</TextSub1>
  </a>
);

export const PrimaryButtonLink = (props: LinkProps) => <BaseLink {...props} variant="primary" />;

export const PrimaryInvertedButtonLink = (props: LinkProps) => (
  <BaseLink {...props} variant="primaryInverted" />
);

export const SecondaryButtonLink = (props: LinkProps) => (
  <BaseLink {...props} variant="secondary" />
);
