import * as React from 'react';
import { Container } from 'ui/components/organisms/container/container';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import { LogoWhite } from 'ui/components/atoms/logo/logo';
import { TextRegularAlt, TextSub1, TextTiny } from 'ui/components/atoms/typography/typography';
import { PrimaryButtonLink, SecondaryButtonLink } from 'ui/components/atoms/link/link';
import Instagram from 'ui/components/atoms/icons/instagram-white.inline.svg';
import Facebook from 'ui/components/atoms/icons/facebook-white.inline.svg';
import Youtube from 'ui/components/atoms/icons/youtube-white.inline.svg';
import styles from './footer.module.css';
import { Social } from 'ui/components/atoms/social/social';
import { MenuItemProps } from 'shared/types/menu';
import { Link } from 'gatsby';
import SiteFooterQuery from 'queries/siteFooter';
import { HtmlSanitizer } from 'util/html';

export type FooterProps = {
  siteTitle: string;
  footerLeftHeading: string;
  footerLeftItems: MenuItemProps[];
  footerRightHeading: string;
  footerRightItems: MenuItemProps[];
  disclaimer: string;
  copyright: string;
  social: {
    instagramHandle: string;
    facebookHandle: string;
    youtubeHandle: string;
  };
  primaryCta: {
    label: string;
    url: string;
  };
  secondaryCta: {
    label: string;
    url: string;
  };
};

const CampaignRow = ({
  primaryCta,
  secondaryCta,
}: {
  primaryCta: FooterProps['primaryCta'];
  secondaryCta: FooterProps['secondaryCta'];
}) => (
  <Row center="md">
    <Col sm={12} md={8} lg={6}>
      <Row>
        <Col sm={12} md={6}>
          <PrimaryButtonLink className={styles.campaignModePrimaryCta} href={primaryCta.url}>
            {primaryCta.label}
          </PrimaryButtonLink>
        </Col>
        <Col sm={12} md={6}>
          <SecondaryButtonLink href={secondaryCta.url}>{secondaryCta.label}</SecondaryButtonLink>
        </Col>
      </Row>
    </Col>
  </Row>
);

const renderColumns = (treatments: MenuItemProps[]): MenuItemProps[][] => {
  return [
    [...treatments.slice(0, Math.ceil(treatments.length / 2))],
    [...treatments.slice(Math.ceil(treatments.length / 2))],
  ];
};

function FooterComponent({ isCampaignMode }: { isCampaignMode: boolean }) {
  const year = new Date().getFullYear();
  return (
    <SiteFooterQuery
      render={({
        siteTitle,
        footerLeftHeading,
        footerLeftItems,
        footerRightHeading,
        footerRightItems,
        disclaimer,
        copyright,
        social,
        primaryCta,
        secondaryCta,
      }: FooterProps) => {
        return (
          <div className={styles.footer}>
            <Container>
              <Row center="sm">
                <Col>
                  <div className={styles.logo}>
                    <LogoWhite />
                  </div>
                </Col>
              </Row>
              {isCampaignMode ? (
                <CampaignRow primaryCta={primaryCta} secondaryCta={secondaryCta} />
              ) : (
                <Row>
                  <Col sm={12} md={8} lg={9}>
                    <Row center="sm" start="md">
                      <Col sm={12} lg={8} className={styles.column}>
                        <TextSub1 tagName="div" color="white50" className={styles.subheading}>
                          {footerLeftHeading}
                        </TextSub1>
                        <Row>
                          {renderColumns(footerLeftItems).map((col, i) => (
                            <Col sm={12} md={6} key={i}>
                              {col.map((treatment, k) => (
                                <FooterItem url={treatment.url} key={k}>
                                  {treatment.label}
                                </FooterItem>
                              ))}
                            </Col>
                          ))}
                        </Row>
                      </Col>
                      <Col sm={12} lg={4} className={styles.socialColumn}>
                        <TextSub1 tagName="div" color="white50" className={styles.subheading}>
                          {footerRightHeading}
                        </TextSub1>
                        <Row start="sm">
                          <Col sm={6}>
                            {footerRightItems.map((x, i) => (
                              <FooterItem url={x.url} key={i}>
                                {x.label}
                              </FooterItem>
                            ))}
                          </Col>
                          <Col sm={6}>
                            <FooterItem
                              url={`https://www.instagram.com/${social.instagramHandle}`}
                              textClassName={styles.socialRow}>
                              <Social color="white" icon={Instagram} text="Instagram" />
                            </FooterItem>
                            <FooterItem
                              url={`https://www.facebook.com/${social.facebookHandle}`}
                              textClassName={styles.socialRow}>
                              <Social color="white" icon={Facebook} text="Facebook" />
                            </FooterItem>
                            <FooterItem
                              url={`https://www.youtube.com/${social.youtubeHandle}`}
                              textClassName={styles.socialRow}>
                              <Social color="white" icon={Youtube} text="Youtube" />
                            </FooterItem>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={12} md={4} lg={3} className={styles.column}>
                    <PrimaryButtonLink className={styles.buttonRow} href={primaryCta.url}>
                      {primaryCta.label}
                    </PrimaryButtonLink>

                    <SecondaryButtonLink href={secondaryCta.url}>
                      {secondaryCta.label}
                    </SecondaryButtonLink>
                  </Col>
                </Row>
              )}

              <Row className={styles.copyrightRow} center="sm">
                <Col sm={12} lg={10}>
                  <TextTiny tagName="div">
                    <span>
                      &copy; Copyright {year} {siteTitle}.
                    </span>{' '}
                    <HtmlSanitizer tagName="span" html={copyright} />
                  </TextTiny>
                  <TextTiny tagName="div" className={styles.dislaimer}>
                    <HtmlSanitizer tagName="span" html={disclaimer} />
                  </TextTiny>
                </Col>
              </Row>
            </Container>
          </div>
        );
      }}
    />
  );
}

const FooterItem = ({
  children,
  textClassName,
  url,
}: {
  children: any;
  textClassName?: string;
  url: string;
}) => (
  <div className={styles.item}>
    <Link to={url} className={styles.anchor}>
      <TextRegularAlt className={textClassName} tagName="span" color="white">
        {children}
      </TextRegularAlt>
    </Link>
  </div>
);

export const Footer = React.memo(FooterComponent);
