import * as React from 'react';

export function useAsync<V, E = any>(
  asyncFunction: (...args: any[]) => Promise<any>,
  immediate = true,
) {
  const [pending, setPending] = React.useState(false);
  const [value, setValue] = React.useState<V | null>(null);
  const [error, setError] = React.useState<E | null>(null);

  const execute = React.useCallback(
    (...args: any[]) => {
      setPending(true);
      setValue(null);
      setError(null);
      return asyncFunction(...args)
        .then(response => setValue(response))
        .catch(error => setError(error))
        .finally(() => setPending(false));
    },
    [asyncFunction],
  );

  React.useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);

  return { execute, pending, value, error };
}
