import React, { useCallback, useContext } from 'react';
import classNames from 'classnames';
import { useAsync } from 'hooks/useAsync';
import { fetchPromise } from 'util/fetch';
import { Container } from 'ui/components/organisms/container/container';
import { Row, Col } from 'ui/components/organisms/grid/grid';
import { TextSub2, TextPriority3, TextRegularAlt } from 'ui/components/atoms/typography/typography';
import { PrimaryButtonInverted } from 'ui/components/atoms/button/button';
import { FormText, FormTextArea } from 'ui/components/molecules/form/form_input';
import { Form, FormSubmitting, FormSuccess } from 'ui/components/molecules/form/form';
import { store } from 'ui/store/store';
import WatermarkO from 'ui/components/atoms/watermarks/watermark-O.inline.svg';
import WatermarkP from 'ui/components/atoms/watermarks/watermark-P.inline.svg';
import styles from './enquiry.module.css';

type EnquiryFields = {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
  message: string;
  h: string;
};

type ResponseError = {
  status: string;
  message: string;
  correlationId: string;
  errors: {
    message: string;
    errorType: string;
  }[];
};

type ResponseSuccess = {
  inlineMessage: string;
};

type EnquiryProps = {
  hubspotFormUrl: string;
  preheading: string;
  heading: string;
  subheading: string;
  isCampaignMode?: boolean;
};

// const submitUrl =
//   'https://api.hsforms.com/submissions/v3/integration/submit/6233066/fa24061f-1981-495f-99c1-3fdeef8515c2';

const Honeytrap = React.forwardRef<HTMLInputElement>(function Honeytrap(_, ref) {
  return <input ref={ref} name="h" />;
});

const EnquiryComponent = ({
  hubspotFormUrl,
  preheading,
  heading,
  subheading,
  isCampaignMode = false,
}: EnquiryProps) => {
  const { dispatch } = useContext(store);
  const ref = useCallback((node: HTMLDivElement) => {
    if (node != null) {
      dispatch({ type: 'REF_NODE_FOOTER_ENQUIRE_SET', node });
    }
  }, []);

  const onSubmit = (data: EnquiryFields) => {
    return execute(data);
  };

  const submitForm = useCallback(
    (data: EnquiryFields) => {
      /**
       * Massage data into Hubspot-friendly format
       *
       * {@see|https://legacydocs.hubspot.com/docs/methods/forms/submit_form}
       *
       */
      const saveData = {
        fields: Object.keys(data).map(key => ({
          name: key,
          value: data[key as keyof EnquiryFields],
        })),
      };

      if (data.h != '') {
        return Promise.reject();
      }

      return fetchPromise(hubspotFormUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(saveData),
      });
    },
    [hubspotFormUrl],
  );

  const { execute, pending, value: success, error } = useAsync<ResponseSuccess, ResponseError>(
    submitForm,
    false,
  );

  return (
    <div className={styles.enquiry} ref={ref}>
      <div className={styles.watermarks}>
        <div className={styles.watermarkO}>
          <WatermarkO />
        </div>
        <div className={styles.watermarkP}>
          <WatermarkP />
        </div>
      </div>
      <Container className={styles.container}>
        <Row center="sm">
          <Col sm={12}>
            <TextSub2 tagName="div" className={styles.subheading}>
              {preheading}
            </TextSub2>
            <TextPriority3 className={styles.heading}>{heading}</TextPriority3>
            <div className={styles.text}>
              <TextRegularAlt>{subheading}</TextRegularAlt>
            </div>
            {pending && <FormSubmitting />}

            {success && (
              <FormSuccess isCampaignMode={isCampaignMode} message={success.inlineMessage} />
            )}

            <Form<EnquiryFields>
              className={classNames(styles.form, {
                [styles.hiddenForm]: success || pending || error,
              })}
              onSubmit={onSubmit}>
              {({ register, errors }) => (
                <>
                  <div className={styles.h}>
                    <Honeytrap ref={register()} />
                  </div>
                  <Row>
                    <Col sm={12} md={6}>
                      <FormText
                        name="firstname"
                        placeholder="First Name *"
                        ref={register({ required: 'Required Field' })}
                        error={errors?.firstname?.message}
                      />
                    </Col>
                    <Col sm={12} md={6}>
                      <FormText
                        name="lastname"
                        placeholder="Last Name *"
                        ref={register({ required: 'Required Field' })}
                        error={errors?.lastname?.message}
                      />
                    </Col>
                    <Col sm={12}>
                      <FormText
                        name="email"
                        placeholder="Email Address *"
                        ref={register({
                          required: 'Required Field',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                          },
                        })}
                        error={errors?.email?.message}
                      />
                    </Col>
                    <Col sm={12}>
                      <FormText
                        name="phone"
                        placeholder="Phone *"
                        ref={register({
                          required: 'Required Field',
                          pattern: {
                            value: /^[\d ]{7,20}$/i,
                            message: 'Invalid Phone Number. Numbers and spaces only',
                          },
                        })}
                        error={errors?.phone?.message}
                      />
                    </Col>
                    <Col sm={12}>
                      <FormTextArea
                        name="message"
                        placeholder="Your Message *"
                        ref={register({
                          required: 'Required Field',
                        })}
                        error={errors?.message?.message}
                      />
                    </Col>
                  </Row>
                  <PrimaryButtonInverted type="submit" className={styles.submit}>
                    Submit
                  </PrimaryButtonInverted>
                </>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export const Enquiry = React.memo(EnquiryComponent);
