import React, { useState, useEffect } from 'react';

type OnScreenHookProps = {
  ref: React.MutableRefObject<Element | null>;
  rootMargin?: string;
  initialState?: boolean;
};

export function useOnScreen({ ref, rootMargin = '0px', initialState = false }: OnScreenHookProps) {
  const [isIntersecting, setIntersecting] = useState(initialState);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isIntersecting;
}
