import * as React from 'react';
import { graphql, StaticQuery, StaticQueryProps } from 'gatsby';
import { MenuGQLProps } from 'shared/types/menu';
import { FooterProps } from 'ui/components/organisms/footer/footer';

interface Props {
  render: Exclude<StaticQueryProps['render'], undefined>;
}

export type FooterOperation = {
  wp: {
    globalOptions: {
      acfGlobalOptions: {
        instagram: {
          handle: string | null;
        };
        facebook: {
          handle: string | null;
        };
        youtube: {
          handle: string | null;
        };
        footer: {
          leftPreheading: string | null;
          rightPreheading: string | null;
          disclaimer: string | null;
          copyright: string | null;
          primaryCta: {
            label: string | null;
            url: string | null;
          };
          secondaryCta: {
            label: string | null;
            url: string | null;
          };
        };
      };
    };
    generalSettings: {
      title: string;
    };
  };
  footerLeft: MenuGQLProps;
  footerRight: MenuGQLProps;
};

const SiteFooterQuery = ({ render }: Props) => (
  <StaticQuery
    query={graphql`
      query Footer {
        wp {
          globalOptions {
            acfGlobalOptions {
              instagram {
                handle
              }
              facebook {
                handle
              }
              youtube {
                handle
              }
              footer {
                leftPreheading
                rightPreheading
                copyright
                disclaimer
                primaryCta {
                  label
                  url
                }
                secondaryCta {
                  label
                  url
                }
              }
            }
          }
          generalSettings {
            title
          }
        }
        footerLeft: wpMenu(slug: { eq: "footer-lhs" }) {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
        footerRight: wpMenu(slug: { eq: "footer-rhs" }) {
          menuItems {
            nodes {
              label
              url
            }
          }
        }
      }
    `}
    render={(data: FooterOperation) => {
      const props: FooterProps = {
        siteTitle: data.wp.generalSettings.title,
        footerLeftItems: data.footerLeft.menuItems.nodes,
        footerLeftHeading: data.wp.globalOptions.acfGlobalOptions.footer.leftPreheading ?? '',
        footerRightItems: data.footerRight.menuItems.nodes,
        footerRightHeading: data.wp.globalOptions.acfGlobalOptions.footer.rightPreheading ?? '',
        disclaimer: data.wp.globalOptions.acfGlobalOptions.footer.disclaimer ?? '',
        copyright: data.wp.globalOptions.acfGlobalOptions.footer.copyright ?? '',
        social: {
          instagramHandle: data.wp.globalOptions.acfGlobalOptions.instagram.handle ?? '',
          facebookHandle: data.wp.globalOptions.acfGlobalOptions.facebook.handle ?? '',
          youtubeHandle: data.wp.globalOptions.acfGlobalOptions.youtube.handle ?? '',
        },
        primaryCta: {
          label:
            data.wp.globalOptions.acfGlobalOptions.footer.primaryCta.label ?? 'Book Appointment',
          url: data.wp.globalOptions.acfGlobalOptions.footer.primaryCta.url ?? '/',
        },
        secondaryCta: {
          label: data.wp.globalOptions.acfGlobalOptions.footer.secondaryCta.label ?? 'Contact Us',
          url: data.wp.globalOptions.acfGlobalOptions.footer.secondaryCta.url ?? '/contact/',
        },
      };
      return render(props);
    }}
  />
);

export default SiteFooterQuery;
