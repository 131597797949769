import * as React from 'react';
import { CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings_ctas } from 'templates/gql_types/CampaignPageQuery';
import { PrimaryButtonLink } from 'ui/components/atoms/link/link';
import { Container } from 'ui/components/organisms/container/container';
import { Col, Row } from 'ui/components/organisms/grid/grid';
import styles from './campaign_navigation.module.css';

type Props = {
  ctas: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings_ctas[];
};

export const CampaignNavigation = ({ ctas }: Props) => {
  return (
    <Container className={styles.container}>
      <Row end="lg" middle="lg" className={styles.row}>
        {ctas.map((cta, i) => (
          <Col key={i}>
            <PrimaryButtonLink className={styles.button} href={cta.href}>
              {cta.label}
            </PrimaryButtonLink>
          </Col>
        ))}
      </Row>
    </Container>
  );
};
