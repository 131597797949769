import * as React from 'react';
import classNames from 'classnames';
import styles from './container.module.css';

type TagName = 'div' | 'section';

type ContainerProps = {
  className?: string;
  tagName?: TagName;
  children?: any; // eslint-disable-line
};

export const Container = ({
  children,
  tagName = 'div',
  className,
}: ContainerProps): JSX.Element => {
  return React.createElement(
    tagName,
    {
      className: classNames(styles.container, className),
    },
    children,
  );
};

export const PageContainer = (props: ContainerProps) => (
  <Container {...props} className={styles.pageContainer} />
);
