import * as React from 'react';
import classNames from 'classnames';
import { TextSub1 } from 'ui/components/atoms/typography/typography';
import styles from './button.module.css';

type ButtonProps = {
  type?: 'button' | 'submit';
  children?: any;
  className?: string;
  onClick?: () => void;
};

export type ButtonVariants = 'primary' | 'primaryInverted' | 'secondary';

const Button = ({
  type = 'button',
  variant,
  children,
  className,
  onClick,
}: ButtonProps & { variant: ButtonVariants }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        styles.button,
        {
          [styles.primary]: variant === 'primary',
          [styles.primaryInverted]: variant === 'primaryInverted',
          [styles.secondary]: variant === 'secondary',
        },
        className,
      )}>
      <TextSub1 color="inherit">{children}</TextSub1>
    </button>
  );
};

export const PrimaryButton = (props: ButtonProps) => <Button {...props} variant="primary" />;

export const PrimaryButtonInverted = (props: ButtonProps) => (
  <Button {...props} variant="primaryInverted" />
);

export const SecondaryButton = (props: ButtonProps) => <Button {...props} variant="secondary" />;
