// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blank-tsx": () => import("./../../../src/templates/blank.tsx" /* webpackChunkName: "component---src-templates-blank-tsx" */),
  "component---src-templates-campaign-tsx": () => import("./../../../src/templates/campaign.tsx" /* webpackChunkName: "component---src-templates-campaign-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-pricing-tsx": () => import("./../../../src/templates/pricing.tsx" /* webpackChunkName: "component---src-templates-pricing-tsx" */),
  "component---src-templates-rte-tsx": () => import("./../../../src/templates/rte.tsx" /* webpackChunkName: "component---src-templates-rte-tsx" */),
  "component---src-templates-treatment-category-tsx": () => import("./../../../src/templates/treatment_category.tsx" /* webpackChunkName: "component---src-templates-treatment-category-tsx" */),
  "component---src-templates-treatment-detail-tsx": () => import("./../../../src/templates/treatment_detail.tsx" /* webpackChunkName: "component---src-templates-treatment-detail-tsx" */)
}

