import { Link } from 'gatsby';
import React, { useRef, useContext, useCallback } from 'react';
import classNames from 'classnames';
import { useOnScreen } from 'hooks/useOnScreen';
import { Burger } from 'ui/components/molecules/burger/burger';
import { Nav } from 'ui/components/organisms/nav/nav';
import { Logo } from 'ui/components/atoms/logo/logo';
import { store } from 'ui/store/store';
import styles from './header.module.css';
import { CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings_ctas } from 'templates/gql_types/CampaignPageQuery';
import { CampaignNavigation } from 'ui/components/organisms/nav/campaign_navigation';
import { TextRegularAlt } from 'ui/components/atoms/typography/typography';
import { WYSIWYG } from 'ui/components/molecules/wysiwyg/wysiwyg';
import { exists } from 'util/exists';

type Props = {
  campaignPageCTAs?: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings_ctas[];
  alertMessage?: string;
};

function HeaderComponent({ campaignPageCTAs, alertMessage }: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const onScreen = useOnScreen({ ref, initialState: true });
  const isCampaignMode = campaignPageCTAs && Array.isArray(campaignPageCTAs);
  const hasAlertMessage = exists(alertMessage);

  const { dispatch, state } = useContext(store);
  const { headerMobile } = state;
  const hideNav = useCallback(() => dispatch({ type: 'HEADER_MOBILE_HIDE' }), []);
  const toggleNav = useCallback(() => dispatch({ type: 'HEADER_MOBILE_TOGGLE' }), []);

  return (
    <React.Fragment>
      <div
        ref={ref}
        className={classNames(styles.observer, {
          [styles.observerPlusAlert]: hasAlertMessage,
        })}
      />
      <header className={styles.container}>
        {alertMessage && (
          <div className={styles.headerAlert}>
            <TextRegularAlt tagName="div" className={styles.headerAlertText}>
              <WYSIWYG html={alertMessage} />
            </TextRegularAlt>
          </div>
        )}

        <div
          className={classNames(styles.header, {
            [styles.headerActive]: !onScreen,
          })}>
          <div className={styles.logoContainer}>
            <Link
              onClick={hideNav}
              to="/"
              className={classNames(styles.logo, { [styles.logoActive]: !onScreen })}>
              <Logo />
            </Link>
          </div>
          {isCampaignMode ? (
            // eslint-disable-next-line
            <CampaignNavigation ctas={campaignPageCTAs!} />
          ) : null}
        </div>
      </header>
    </React.Fragment>
  );
}

export const Header = React.memo(HeaderComponent);
