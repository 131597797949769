import * as React from 'react';
import classNames from 'classnames/bind';
import styles from './grid.module.css';

const cx = classNames.bind(styles);

type ViewportSizeType = 'sm' | 'md' | 'lg' | 'xl';
type TagName = 'div' | 'section';

interface RowPropTypes {
  start?: ViewportSizeType;
  center?: ViewportSizeType;
  end?: ViewportSizeType;
  top?: ViewportSizeType;
  middle?: ViewportSizeType;
  bottom?: ViewportSizeType;
  around?: ViewportSizeType;
  between?: ViewportSizeType;
  reverse?: boolean;
  className?: string;
  tagName?: TagName;
  children?: any; // eslint-disable-line
}

type RowFlexboxClassNames = Omit<RowPropTypes, 'tagName' | 'children' | 'reverse' | 'className'>;

/***
 * @param props
 *
 * Map the RowPropTypes to the CSS Property inside grid.css.
 */
const mapRowCSSClass = (props: RowFlexboxClassNames) => {
  const keys = Object.keys(props) as Array<keyof typeof props>;
  const cssClass = keys.map(x => ({
    [`${x}${props[x]?.toUpperCase()}`]: true,
  }));
  return Object.assign({}, ...cssClass);
};

export const Row = ({
  tagName = 'div',
  reverse = false,
  children,
  className,
  ...props
}: RowPropTypes) => {
  const flexClassNames = mapRowCSSClass(props);
  const rowClassNames = cx({
    row: true,
    rowReverse: reverse,
    ...flexClassNames,
  });
  return React.createElement(
    tagName,
    { className: classNames(rowClassNames, className) },
    children,
  );
};

type Columns = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
interface ColPropTypes {
  sm?: Columns;
  md?: Columns;
  lg?: Columns;
  xl?: Columns;
  smOffset?: number;
  mdOffset?: number;
  lgOffset?: number;
  xlOffset?: number;
  first?: ViewportSizeType;
  last?: ViewportSizeType;
  tagName?: TagName;
  children?: any; // eslint-disable-line
  className?: string;
}

type ColFlexboxClassNames = Omit<ColPropTypes, 'tagName' | 'children' | 'className'>;

/***
 * @param props
 *
 * Map the ColPropTypes to the CSS Property inside grid.css. Only works on sm|md|lg and smOffset|mdOffset|lgOffset
 */
const mapColCSSClass = (props: ColFlexboxClassNames) => {
  const keys = Object.keys(props) as Array<keyof typeof props>;
  const cssClass = keys.map(x => {
    const initialCharsUpper = x.slice(0, 2).toUpperCase();
    const key = `col${initialCharsUpper}${x.slice(2).toLowerCase()}${props[x]}`;
    return {
      [key]: true,
    };
  });
  return Object.assign({}, ...cssClass);
};

export const Col = ({
  tagName = 'div',
  first,
  last,
  children,
  className,
  ...props
}: ColPropTypes) => {
  const flexClassNames = mapColCSSClass(props);
  const firstClassName = first
    ? {
        [`first${first.toUpperCase()}`]: true,
      }
    : null;

  const lastClassName = last
    ? {
        [`last${last.toUpperCase()}`]: true,
      }
    : null;

  const colClassNames = cx({
    col: true,
    ...firstClassName,
    ...lastClassName,
    ...flexClassNames,
  });
  return React.createElement(
    tagName,
    { className: classNames(colClassNames, className) },
    children,
  );
};
