import * as React from 'react';
import { graphql, StaticQuery, StaticQueryProps } from 'gatsby';

interface Props {
  render: Exclude<StaticQueryProps['render'], undefined>;
}

export type EnquiryOperation = {
  wp: {
    globalOptions: {
      acfGlobalOptions: {
        enquiryForm: {
          hubspotFormUrl: string;
          preheading: string;
          heading: string;
          subheading: string;
        };
      };
    };
    generalSettings: {
      title: string;
    };
  };
};

const SiteEnquiryQuery = ({ render }: Props) => (
  <StaticQuery
    query={graphql`
      query Enquiry {
        wp {
          globalOptions {
            acfGlobalOptions {
              enquiryForm {
                hubspotFormUrl
                preheading
                heading
                subheading
              }
            }
          }
        }
      }
    `}
    render={(props: EnquiryOperation) => {
      return render(props);
    }}
  />
);

export default SiteEnquiryQuery;
