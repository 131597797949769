import * as React from 'react';
import { useForm, SubmitHandler, UseFormMethods } from 'react-hook-form';
import { navigate } from 'gatsby';
import styles from './form.module.css';
import { TextPriority3, TextSub1 } from 'ui/components/atoms/typography/typography';

type FormProps<TFormValues> = {
  onSubmit: SubmitHandler<TFormValues>;
  className?: string;
  children: (methods: UseFormMethods<TFormValues>) => React.ReactNode;
};

export const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  onSubmit,
  children,
  className,
}: FormProps<TFormValues>) => {
  const methods = useForm<TFormValues>();
  return (
    <form className={className} onSubmit={methods.handleSubmit(onSubmit)}>
      {children(methods)}
    </form>
  );
};

export const FormSubmitting = () => (
  <div className={styles.submitting}>
    <div className={styles.submittingBalls}></div>
    <div className={styles.submittingBalls}></div>
    <div className={styles.submittingBalls}></div>
  </div>
);

const FormSuccessComponent = ({
  message,
  isCampaignMode = false, // eslint-disable-line
}: {
  message: string;
  isCampaignMode?: boolean;
}) => {
  if (typeof window !== 'undefined') {
    navigate('/contact/thank-you/');
  }
  return (
    <div className={styles.success} data-id="enquiry-success">
      <TextSub1>Success!</TextSub1>
      <TextPriority3>{message}</TextPriority3>
    </div>
  );
};

export const FormSuccess = React.memo(FormSuccessComponent);
