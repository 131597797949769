import * as React from 'react';
import { TextSmall } from 'ui/components/atoms/typography/typography';
import classNames from 'classnames';
import styles from './form_input.module.css';

type FormTextProps<T extends HTMLElement> = React.DetailedHTMLProps<
  React.InputHTMLAttributes<T>,
  T
> & {
  placeholder: string;
  error?: string;
};

export const FormText = React.forwardRef<HTMLInputElement, FormTextProps<HTMLInputElement>>(
  function FormText({ error, ...rest }, ref) {
    return (
      <Input error={error}>
        <input className={styles.inputText} ref={ref} {...rest} />
      </Input>
    );
  },
);

export const FormTextArea = React.forwardRef<
  HTMLTextAreaElement,
  FormTextProps<HTMLTextAreaElement>
>(function FormTextArea({ error, ...rest }, ref) {
  return (
    <Input error={error}>
      <textarea rows={1} className={styles.textarea} ref={ref} {...rest} />
    </Input>
  );
});

const Input = ({ children, error }: { children: any; error?: string }) => {
  return (
    <div className={classNames(styles.input, { [styles.inputError]: Boolean(error) })}>
      {children}
      {error && (
        <div className={styles.error}>
          <TextSmall color="inherit">{error}</TextSmall>
        </div>
      )}
    </div>
  );
};
