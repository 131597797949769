import classNames from 'classnames';
import * as React from 'react';
import { HtmlSanitizer } from 'util/html';
import styles from './wysiwyg.module.css';

type WYSIWYGProps = {
  html: string;
  tagName?: 'div' | 'span';
  maxWidth?: boolean;
};

export const WYSIWYG = ({ html, tagName = 'div', maxWidth = true }: WYSIWYGProps) => {
  return (
    <HtmlSanitizer
      tagName={tagName}
      html={html}
      className={classNames(styles.wysiwyg, {
        [styles.maxWidth]: maxWidth,
      })}
    />
  );
};
