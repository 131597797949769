import * as React from 'react';
import { Meta } from 'ui/components/molecules/meta/meta';
import { Header } from 'ui/components/organisms/header/header';
import { PageContent } from 'shared/types/pages';
import { Footer } from 'ui/components/organisms/footer/footer';
import { Enquiry } from 'ui/components/organisms/enquiry/enquiry';
import { Provider } from 'ui/store/store';
import SiteEnquiryQuery, { EnquiryOperation } from 'queries/siteEnquiry';
import { CampaignPageAlert, CampaignPageNavigation } from 'shared/gql_types/globalTypes';
import { CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings } from 'templates/gql_types/CampaignPageQuery';
import { exists } from 'util/exists';
import { WpGlobal } from 'shared/types/wp_global';

interface LayoutProps {
  pageData?: {
    wpPage: Partial<PageContent> & Partial<CampaignMode>;
    wpGlobal: WpGlobal;
  };
  children: React.ReactNode;
}

type CampaignMode = {
  acfTemplateCampaign: {
    campaign: {
      pageSettings: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings;
    };
  };
};

export const getIsCampaignMode = (navigation: CampaignPageNavigation | undefined): boolean => {
  switch (navigation) {
    case CampaignPageNavigation.HIDDEN:
      return true;
    case CampaignPageNavigation.STANDARD:
    default:
      return false;
  }
};

/**
 * On Campaign template, admin can optionally override alert visibility
 * TRUE means HIDE PINK NAV
 * Otherwise, undefined or false is SHOW PINK NAV.
 */
const getCampaignPageHideAlert = (alert: CampaignPageAlert | undefined): boolean => {
  switch (alert) {
    case CampaignPageAlert.HIDDEN:
      return true;
    case CampaignPageAlert.SHOWN:
    default:
      return false;
  }
};

const getCampaignCTAs = (
  ctas: CampaignPageQuery_wpPage_acfTemplateCampaign_campaign_pageSettings['ctas'] | undefined,
) => {
  return ctas ? ctas.filter(exists) : [];
};

export const Layout = ({ children, pageData }: LayoutProps) => {
  const maybeCampaignPageSettings = pageData?.wpPage?.acfTemplateCampaign?.campaign?.pageSettings;

  const isCampaignMode = maybeCampaignPageSettings
    ? getIsCampaignMode(maybeCampaignPageSettings.navigation)
    : false;

  const campaignPageCTAs = isCampaignMode
    ? getCampaignCTAs(maybeCampaignPageSettings?.ctas)
    : undefined;

  // On Campaign template, admin can optionally override alert
  // Default is HIDDEN
  const campaignPageAlertHiddenOverride = maybeCampaignPageSettings
    ? getCampaignPageHideAlert(maybeCampaignPageSettings.alert)
    : false;

  return (
    <Provider>
      <Meta metafields={pageData?.wpPage.seo} />
      <Header
        campaignPageCTAs={campaignPageCTAs}
        alertMessage={
          campaignPageAlertHiddenOverride != true &&
          pageData?.wpGlobal.globalOptions.acfGlobalOptions.headerAlert
            ? pageData?.wpGlobal.globalOptions.acfGlobalOptions.headerAlert
            : undefined
        }
      />
      {children}
      <SiteEnquiryQuery
        render={(gql: EnquiryOperation) => (
          <Enquiry
            hubspotFormUrl={gql.wp.globalOptions.acfGlobalOptions.enquiryForm.hubspotFormUrl}
            preheading={gql.wp.globalOptions.acfGlobalOptions.enquiryForm.preheading}
            heading={gql.wp.globalOptions.acfGlobalOptions.enquiryForm.heading}
            subheading={gql.wp.globalOptions.acfGlobalOptions.enquiryForm.subheading}
            isCampaignMode={false}
          />
        )}
      />
      <Footer isCampaignMode={true} />
    </Provider>
  );
};
