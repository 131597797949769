import * as React from 'react';
import classNames from 'classnames';
import styles from './typography.module.css';

type TextProps = {
  tagName?: AllowedTagNames;
  className?: string;
  children?: any;
  color?: AllowedColors;
  bottomSpacing?: Spacing;
};

type AllowedTagNames = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'div';
type AllowedColors = 'navy' | 'navy90' | 'white' | 'white50' | 'inherit' | 'colorRed90';

export enum Spacing {
  NONE = 'NONE',
  SPACING_8 = 'SPACING_8',
  SPACING_16 = 'SPACING_16',
  SPACING_24 = 'SPACING_24',
  SPACING_32 = 'SPACING_32',
  SPACING_40 = 'SPACING_40',
}

const createText = ({
  tagName,
  className,
  color,
  children,
  bottomSpacing,
}: TextProps & { tagName: AllowedTagNames }) => {
  return React.createElement(
    tagName,
    {
      className: classNames(className, {
        [styles.navy]: color === 'navy',
        [styles.navy90]: color === 'navy90',
        [styles.white]: color === 'white',
        [styles.white50]: color === 'white50',
        [styles.inherit]: color === 'inherit',
        [styles.spacingBottomNone]: bottomSpacing === Spacing.NONE,
        [styles.spacingBottom8]: bottomSpacing === Spacing.SPACING_8,
        [styles.spacingBottom16]: bottomSpacing === Spacing.SPACING_16,
        [styles.spacingBottom24]: bottomSpacing === Spacing.SPACING_24,
        [styles.spacingBottom32]: bottomSpacing === Spacing.SPACING_32,
        [styles.spacingBottom40]: bottomSpacing === Spacing.SPACING_40,
      }),
    },
    children,
  );
};

export const TextPriority1 = ({
  tagName = 'h1',
  color = 'navy',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.priority1, className),
    color,
    ...props,
  });
};

export const TextPriority2 = ({
  tagName = 'h2',
  color = 'navy',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.priority2, className),
    color,
    ...props,
  });
};

export const TextPriority3 = ({
  tagName = 'h3',
  color = 'navy',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.priority3, className),
    color,
    ...props,
  });
};

export const TextPriority4 = ({
  tagName = 'h4',
  color = 'navy',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.priority4, className),
    color,
    ...props,
  });
};

export const TextPriority5 = ({
  tagName = 'h5',
  color = 'navy',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.priority5, className),
    color,
    ...props,
  });
};

export const TextPriority6 = ({
  tagName = 'h6',
  color = 'navy',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.priority6, className),
    color,
    ...props,
  });
};

/**
 * Paragraph Styles &lt;p&gt;
 */
export const TextRegular = ({
  tagName = 'p',
  color = 'navy90',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.regular, className),
    color,
    ...props,
  });
};

export const TextRegularAlt = ({
  tagName = 'p',
  color = 'navy90',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    className: classNames(styles.regularAlt, className),
    color,
    ...props,
  });
};

export const TextSub1 = ({ tagName = 'span', color = 'navy', className, ...props }: TextProps) => {
  return createText({
    tagName,
    color,
    className: classNames(styles.sub1, className),
    ...props,
  });
};

export const TextSub2 = ({ tagName = 'span', color = 'navy', className, ...props }: TextProps) => {
  return createText({
    tagName,
    color,
    className: classNames(styles.sub2, className),
    ...props,
  });
};

export const TextSub3 = ({ tagName = 'span', color = 'navy', className, ...props }: TextProps) => {
  return createText({
    tagName,
    color,
    className: classNames(styles.sub3, className),
    ...props,
  });
};

export const TextSmall = ({
  tagName = 'span',
  color = 'colorRed90',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    color,
    className: classNames(styles.small, className),
    ...props,
  });
};

export const TextTiny = ({
  tagName = 'span',
  color = 'white50',
  className,
  ...props
}: TextProps) => {
  return createText({
    tagName,
    color,
    className: classNames(styles.tiny, className),
    ...props,
  });
};
