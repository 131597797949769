/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Number of Columns
 */
export enum BlocksTableEnum {
  FOUR = "FOUR",
  ONE = "ONE",
  THREE = "THREE",
  TWO = "TWO",
}

export enum CampaignPageAlert {
  HIDDEN = "HIDDEN",
  SHOWN = "SHOWN",
}

export enum CampaignPageNavigation {
  HIDDEN = "HIDDEN",
  STANDARD = "STANDARD",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
