import * as React from 'react';
import { TextRegularAlt } from '../typography/typography';
import classNames from 'classnames';
import styles from './social.module.css';

type SocialProps = {
  icon: string;
  text: string;
  color?: 'white' | 'navy';
};

export const Social = ({ icon: Icon, text, color = 'navy' }: SocialProps) => (
  <div className={styles.social}>
    <div className={styles.icon}>
      <Icon />
    </div>
    <TextRegularAlt
      tagName="div"
      className={classNames({
        [styles.navy]: color === 'navy',
        [styles.white]: color === 'white',
      })}>
      {text}
    </TextRegularAlt>
  </div>
);
