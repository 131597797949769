function handleErrors(response: Response) {
  return response.json().then(json => {
    if (!response.ok) {
      const error = Object.assign({}, json, {
        status: response.status,
        statusText: response.statusText,
      });
      return Promise.reject(error);
    }
    return json;
  });
}

export function fetchPromise<T extends any>(url: string, options?: RequestInit): Promise<T> {
  return fetch(url, options).then(handleErrors);
}
